import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import ReactPlayer from 'react-player'
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { server } from "../../server";
import styles from "../../styles/styles";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "./Ratings";
import axios from "axios";
import ReactImageGallery from "react-image-gallery";
const ProductDetails = ({ data }) => {

  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.products);
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);
  const [UGS, setUGS] = useState('');
  const [Urlimage, setUrlimage] = useState('');
  const [DesColor, setDesColor] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedColor, setSelectedColor] = useState(null);
  const [Stock, setStock] = useState(0);
 
        

 
  const generateImageUrl = (codArt, color) => {
    // Default to the first color if no color is selected
    const colorToUse = color || (data?.Colors && data?.Colors[0]?.DesColor);
    const imageBaseUrl = `https://scooter-promotos.tn/photos/${codArt}/${colorToUse}`;
  
    // Return an object with original URLs
    return {
      original: `${imageBaseUrl}/promotos-scooter-electrique.jpg`,
      view: `${imageBaseUrl}/Promotos-scooter-view.jpg`,
    };
  };
  
  // Usage in your component
  const productDetailItem = {
    images: [
      generateImageUrl(data?.CodArt, DesColor),
      generateImageUrl(data?.CodArt, DesColor)
    ]
  };

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

 

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i.UGS === id);
    if (isItemExists) {
      toast.error("Produit déjà dans le panier !");
    } else {
      if (data.stock < 1) {
        toast.error("Stock de produits limité !");
      } else {
        const cartData = { ...data, qty: count , DesColor : DesColor , UGS : UGS , Urlimage : Urlimage};
        dispatch(addTocart(cartData));
        toast.success("Produit ajouté au panier avec succès!");
      }
    }
  };

  useEffect(()=>{
    if(data){
   
      setSelectedColor(data.Colors[0]['ID'])
      setUGS(data.Colors[0]['UGS'])
      setDesColor(data.Colors[0]['DesColor'])
      setStock(data.Colors[0]['stock'])
      setUrlimage(data.Colors[0]['Urlimage'])
    }

  },[data])
  
  // const totalReviewsLength =
  //   products &&
  //   products.reduce((acc, product) => acc + product.reviews.length, 0);

  // const totalRatings =
  //   products &&
  //   products.reduce(
  //     (acc, product) =>
  //       acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
  //     0
  //   );

  // const avg =  totalRatings / totalReviewsLength || 0;

  // const averageRating = avg.toFixed(2);


  // const handleMessageSubmit = async () => {
  //   if (isAuthenticated) {
  //     const groupTitle = data._id + user._id;
  //     const userId = user._id;
  //     const sellerId = data.shop._id;
  //     await axios
  //       .post(`${server}/conversation/create-new-conversation`, {
  //         groupTitle,
  //         userId,
  //         sellerId,
  //       })
  //       .then((res) => {
  //         navigate(`/inbox?${res.data.conversation._id}`);
  //       })
  //       .catch((error) => {
  //         toast.error(error.response.data.message);
  //       });
  //   } else {
  //     toast.error("Please login to create a conversation");
  //   }
  // };
  const openAndPrintPdf = () => {
    const pdfUrl = 'https://scooter-promotos.tn/photos/catalogue_Promotos_Scooters_%C3%A9lectriques_V4_page-0005'; // Remplacez par votre URL

    const newWindow = window.open(pdfUrl, '_blank');

    if (newWindow) {
        newWindow.onload = () => {
            newWindow.print();
        };
    } else {
        alert('Le popup a été bloqué. Veuillez autoriser les popups pour cette page.');
    }
};
  return (
    <div className="bg-white">
      {data ? (
        <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
          <div className="w-full py-5">
            <div className="block w-full 800px:flex">
            
               <div className="w-full 800px:w-[50%]">
                <img
                  src={`${data && productDetailItem.images[select].original}`}
                  alt=""
                  className="w-[80%]"
                />
                <div className="w-full flex">
                  {data &&
                   productDetailItem.images.map((i, index) => (
                      <div
                        className={`${
                          select === 0 ? "border" : "null"
                        } cursor-pointer`}
                      >
                        <img
                          src={`${i?.original}`}
                          alt=""
                          className="h-[200px] overflow-hidden mr-3 mt-3"
                          onClick={() => setSelect(index)}
                        />
                      </div>
                    ))}
                  <div
                    className={`${
                      select === 1 ? "border" : "null"
                    } cursor-pointer`}
                  ></div>
                </div>
              </div> 
              <div className="w-full 800px:w-[50%] pt-5">
                <h1 className={`${styles.productTitle}`}>{data.LibArt}</h1>
                 <p>{data.description}</p> 
                <div className="flex pt-3">
                  <h4 className={`${styles.productDiscountPrice}`}>
                    {data.PrixTTC.toFixed(3)} DT
                  </h4>
                  
                </div>
                <div className="flex pt-3">
                  <h4 > Couleur :
                    {DesColor} 
                  </h4>
                </div>
                <div className="flex pt-3">
                  <h4 > Stock :
                    <strong className="mx-2 text-red-600">{Stock}</strong>
                  </h4>
                </div>
                <div className="flex pt-3">
                  <h4 > UGS :
                    {UGS} 
                  </h4>
                  </div>
                  
                 <div className="flex mt-4">
              {data.Colors.map((color, index) => (
                <div
                  key={index}
                 
                  className={`w-10 h-10 mr-2 cursor-pointer rounded-full border-2 ${
                    selectedColor === color.ID ? 'border-black' : ''
                    
                  }`}
                  style={{
                    backgroundColor: color.ID,
                   boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)' 
                  }}
                  onClick={() => {setSelectedColor(color.ID)
                    setDesColor(color.DesColor)
                    setUGS(color.UGS)
                    setStock(color.stock)
                    setUrlimage(color.Urlimage)
                   
                  }} // Update the selected color
                />
              ))}
            </div> 
                <div className="flex items-center mt-12 justify-between pr-3">
                  <div>
                    <button
                      className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                      onClick={decrementCount}
                    >
                      -
                    </button>
                    <span className="bg-gray-200 text-gray-800 font-medium px-4 py-[11px]">
                      {count}
                    </span>
                    <button
                      className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                      onClick={incrementCount}
                    >
                      +
                    </button>
                  </div>
                 
                </div>
                <div className="flex items-center mt-12 justify-betwenn pr-3">
                <div
                  className={`${styles.button} !mt-6 !rounded !h-11 flex items-center`}
                  onClick={() => addToCartHandler(UGS)}
                >
                  <span className="text-white flex items-center">
                    Ajouter au panier <AiOutlineShoppingCart className="ml-1" />
                  </span>
                </div>
                <div
                  className={`${styles.button} !mt-6 !rounded !h-11 flex items-center mx-px`}
                  onClick={() => openAndPrintPdf()}
                >
                  <span className="text-white flex items-center">
                    Fiche Technique 
                  </span>
                </div>
                </div>
                <ReactPlayer url={data.UrlVideo}
                        //  width='100%'
                        //  height='100%'
                         controls = {true}
                           
                        />
              
              </div>
            </div>
          </div>
        
          <br />
          <br />
        </div>
      ) : null}
    </div>
  );
};

const ProductDetailsInfo = ({
  data,
  products,
  totalReviewsLength,
  averageRating,
}) => {
  const [active, setActive] = useState(1);

  return (
    <div className="bg-[#f5f6fb] px-3 800px:px-10 py-2 rounded">
      <div className="w-full flex justify-between border-b pt-10 pb-2">
        <div className="relative">
          <h5
            className={
              "text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            }
            onClick={() => setActive(1)}
          >
            Product Details
          </h5>
          {active === 1 ? (
            <div className={`${styles.active_indicator}`} />
          ) : null}
        </div>
        <div className="relative">
          <h5
            className={
              "text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            }
            onClick={() => setActive(2)}
          >
            Product Reviews
          </h5>
          {active === 2 ? (
            <div className={`${styles.active_indicator}`} />
          ) : null}
        </div>
        <div className="relative">
          <h5
            className={
              "text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
            }
            onClick={() => setActive(3)}
          >
            Seller Information
          </h5>
          {active === 3 ? (
            <div className={`${styles.active_indicator}`} />
          ) : null}
        </div>
      </div>
      {active === 1 ? (
        <>
          <p className="py-2 text-[18px] leading-8 pb-10 whitespace-pre-line">
            {data.description}
          </p>
        </>
      ) : null}

      {active === 2 ? (
        <div className="w-full min-h-[40vh] flex flex-col items-center py-3 overflow-y-scroll">
          {data &&
            data.reviews.map((item, index) => (
              <div className="w-full flex my-2">
                <img
                  src={`${item.user.avatar?.url}`}
                  alt=""
                  className="w-[50px] h-[50px] rounded-full"
                />
                <div className="pl-2 ">
                  <div className="w-full flex items-center">
                    <h1 className="font-[500] mr-3">{item.user.name}</h1>
                    <Ratings rating={data?.ratings} />
                  </div>
                  <p>{item.comment}</p>
                </div>
              </div>
            ))}

          <div className="w-full flex justify-center">
            {data && data.reviews.length === 0 && (
              <h5>No Reviews have for this product!</h5>
            )}
          </div>
        </div>
      ) : null}

      {active === 3 && (
        <div className="w-full block 800px:flex p-5">
          <div className="w-full 800px:w-[50%]">
            <Link to={`/shop/preview/${data.shop._id}`}>
              <div className="flex items-center">
                <img
                  src={`${data?.shop?.avatar?.url}`}
                  className="w-[50px] h-[50px] rounded-full"
                  alt=""
                />
                <div className="pl-3">
                  <h3 className={`${styles.shop_name}`}>{data.shop.name}</h3>
                  <h5 className="pb-2 text-[15px]">
                    ({averageRating}/5) Ratings
                  </h5>
                </div>
              </div>
            </Link>
            <p className="pt-2">{data.shop.description}</p>
          </div>
          <div className="w-full 800px:w-[50%] mt-5 800px:mt-0 800px:flex flex-col items-end">
            <div className="text-left">
              <h5 className="font-[600]">
                Joined on:{" "}
                <span className="font-[500]">
                  {data.shop?.createdAt?.slice(0, 10)}
                </span>
              </h5>
              <h5 className="font-[600] pt-3">
                Total Products:{" "}
                <span className="font-[500]">
                  {products && products.length}
                </span>
              </h5>
              <h5 className="font-[600] pt-3">
                Total Reviews:{" "}
                <span className="font-[500]">{totalReviewsLength}</span>
              </h5>
              <Link to="/">
                <div
                  className={`${styles.button} !rounded-[4px] !h-[39.5px] mt-3`}
                >
                  <h4 className="text-white">Visit Shop</h4>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
