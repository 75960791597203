import React, { useState } from "react";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineStar,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";

import { useEffect } from "react";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";

const ProductCard = ({ data,isEvent }) => {

  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

 




  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Article déjà dans le panier !");
    } else {
      if (data.stock < 1) {
        toast.error("Stock de produits limité!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Article ajouté au panier avec succès !");
      }
    }
  };

  return (
    <>
      <div className="w-full h-[380px] bg-white rounded-lg shadow-sm p-3 relative cursor-pointer">
        <div className="flex justify-end"></div>
         <Link to={`${isEvent === true ? `/product/${data.CodArt}?isEvent=true` : `/product/${data.CodArt}`}`}>
          <img
            src={`${data.Url}`}
            alt=""
            className="w-full h-[250px] object-contain"
          />
        </Link>
        {/* <Link to={`/shop/preview/${data?.}`}>
          <h5 className={`${styles.shop_name}`}>{data.shop.name}</h5>
        </Link>  */}
        <Link to={`${isEvent === true ? `/product/${data.CodArt}?isEvent=true` : `/product/${data.CodArt}`}`}>
          <h4 className={`${styles.shop_name}`}>
            {data.LibArt.length > 40 ? data.LibArt.slice(0, 40) + "..." : data.LibArt}
          </h4>

           <div className="flex">
          <Ratings rating={4} />
          </div> 

          <div className="py-2 flex items-center justify-between">
           
            <div className="flex-1">
    {/* Other content can go here if needed */}
  </div>
              <h5 className={`${styles.productDiscountPrice}`}>
                {data.PrixTTC}
                {" DT"}
              </h5>
             
            
           
          </div>
        </Link>

        {/* side options */}
       
      </div>
    </>
  );
};

export default ProductCard;
