import React, { useEffect ,useState } from "react";
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar";
import { DataGrid } from "@material-ui/data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfAdmin } from "../redux/actions/order";
import { Typeahead } from 'react-bootstrap-typeahead';
import { Link } from "react-router-dom";
import { IoBagHandle, IoPieChart, IoPeople, IoCart } from 'react-icons/io5'
import {
  AiOutlineArrowRight,
  AiOutlineCamera,
  AiOutlineDelete,
  AiOutlineLogin,
  AiOutlineEye, AiOutlineCheck
} from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import { FaPrint } from 'react-icons/fa';
 import { Button } from "@material-ui/core";
 import * as XLSX from "xlsx"; // Import XLSX library
 import { server, serverip } from "../server";
 import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
 import Logo from "../Assests/LogoZitouna.png";
 import Logo2 from "../Assests/logo-002.jpg";
const AdminDashboardOrders = () => {
  var day = new Date();
  var dd = ("0" + (day.getDate())).slice(-2);
  var mm = ("0" + (day.getMonth() + 1)).slice(-2);

  const yyyy = day.getFullYear();
  const DateDebut = `${yyyy}-01-01`
  const DateFin = `${yyyy}-${mm}-${dd}`;


  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(DateDebut);
  const [endDate, setEndDate] = useState(DateFin);
  const [searchTerm, setSearchTerm] = useState('');
  const [item,setitem] = useState([]);

  const [statusFilter, setStatusFilter] = useState([]);
  const [agencyFilter, setAgencyFilter] = useState([]);
  const [agentFilter, setAgentFilter] = useState([]);
  const [drFilter, setDrFilter] = useState([]);
  

  const statusOptions = [
    { id: "0", label: "Annulée" },
    { id: "1", label: "En attente" },
    { id: "2", label: "Devis Prêt" },
    { id: "3", label: "en Att. confirmation admin" },
    { id: "4", label: "Confirmée" },
    { id: "5", label: "Livrée" },

  ];
  
  const  BoxWrapper = ({ children }) =>{
    return <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center">{children}</div>
  }
  
  const { adminOrders, adminOrderLoading } = useSelector(
    (state) => state.order
  );

const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

    // Create a file and download it
    XLSX.writeFile(workbook, "orders.xlsx");
  };

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
  }, []);
  const columns = [
    { field: "id", headerName: "N°", flex: 0.45 },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 0.45,
    },
    { field: "CodTiers", headerName: "Code", flex: 0.45 ,hide:true},
    { field: "LibTiers", headerName: "Raison Sociale", minWidth: 150, flex: 0.7 },
    {
      field: "Tel",
      headerName: "Tél",

      flex: 0.4,
    },
    { field: "CodArt", headerName: "CodArt", flex: 0.5, hide: true  },
    { field: "Modele", headerName: "Modele", flex: 0.45 },
    { field: "Color", headerName: "Color", flex: 0.45 },
    { field: "MntTVA", headerName: "MntTVA", flex: 0.35, hide: true  },
    { field: "PuHT", headerName: "PuHT", flex: 0.35, hide: true  },
    { field: "PuTTC", headerName: "PuTTC", flex: 0.35, hide: true  },
    { field: "DureeFinancement", headerName: "Durée", flex: 0.5 },
    { field: "MensualiteFix", headerName: "Mens.Fix", flex: 0.5 },
    { field: "nbr", headerName: "validité", flex: 0.5 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 40,
      flex: 0.4,
      renderCell: (params) => {

        const { status } = params.row;

        let statusText;
        let statusClass;

        switch (status) {
          case "En attente":

            statusClass = 'bg-black text-white';
            break;
            case "Devis Prêt":

            statusClass = 'bg-orange-400 text-orange-100';
            break;
          case "En att. confirmation admin":

            statusClass = 'text-green-600 bg-green-100';
            break;
          case "Confirmée":

            statusClass = 'text-yellow-600 bg-yellow-100';
            break;
          case "Livrée":

            statusClass = 'text-teal-600 bg-teal-100';
            break;
          default:

            statusClass = 'text-red-600 bg-red-100';
        }

        return (
          <span className={`capitalize py-1 px-2 rounded-md text-xs ${statusClass}`}>
            {status.toLowerCase()}
          </span>
        );
      }
    },
    {
      field: "  ",
      flex: 0.8,

      headerName: ".",
      type: "number",
      sortable: false,

      renderCell: (params) => {
        const handlePrint = () => {
         
          const rowData = [] 
          rowData.push(params.row)   // Assuming params.row is your data object
  
  // Define the number of rows you want (20 rows)
  const totalRows = 10;

 
  const totalTable = `
  <table >
   
    <tbody>
      <tr>
        <td><strong>Total H.T</strong></td>
        <td style="text-align: right; border: 1px solid #000;">${params.row?.PuHT?.toFixed(3)}</td>
        </tr>
        
        <tr>
        <td><strong>Tot.Tva</strong></td>
        <td style="text-align: right; border: 1px solid #000;">${params.row?.MntTVA?.toFixed(3)}</td>
        </tr>
        <tr>
        <td><strong>Net à Payer</strong></td>
        <td style="text-align: right; border: 1px solid #000;">${params.row?.PuTTC?.toFixed(3)}</td>
      </tr>
    </tbody>
  </table>
  

`;
  // Build the content of the table rows
  let tableRows = '';
  
  // If rowData has data for multiple rows, loop through them (or just use one row)
  for (let i = 0; i < totalRows; i++) {
    if (i < rowData.length) {
      // If there's actual data (you can customize this depending on how you store your data)
      const currentRow = rowData[i]; // Access row i from data array (if available)
      tableRows += `
        <tr>
          <td>${currentRow.CodArt}</td>
          <td>${currentRow.LibArt}</td>
          <td style="text-align: right;">${currentRow?.PuHT ? currentRow.PuHT.toFixed(3) : ''}</td>
          <td style="text-align: center;">${currentRow?.Qte || 1}</td>
          <td style="text-align: center;">${currentRow?.MntTVA || 0}</td>
          <td style="text-align: center;">${(currentRow?.PuHT * (1 + currentRow?.MntTVA / 100) * (currentRow?.Qte || 1)).toFixed(2) || ''}</td>
        </tr>
      `;
    } else {
      // Add an empty row if there is no data for the row
      tableRows += `
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      `;
    }
  }
 const printContent =`<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Devis Samsung</title>
    <style>
     @media print {
            @page {
              size: A4;
              margin: 10mm;
            }
        body {
            font-family: Arial, sans-serif;
             font-size: 12pt;
              line-height: 1;
            margin: 20px;
            padding: 0;
            background-color: #f9f9f9;
            color: #333;
        }
        .container {
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 5px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        h1 {
            text-align: center;
        }
        .info {
           margin: 10px 0;
            padding-bottom: 10px;
            border-bottom: 1px solid #ddd;
        }
        .info strong {
            display: inline-block;
            width: 200px;
        }
         .images{
         display : flex ; 
         
              justify-content: start;
         }   
              .img{
              border-right: 1px solid #ddd;
              margin : 15px;
              padding : 5px;
              }

        .img2{
         margin-Top : 15px;
          padding : 5px;
        }
       
     }
    </style>
</head>
<body>
    <div class="container">
     <div class="images">
       <div class="img">
            <img width="150" height="40" src="https://scooter-promotos.tn/photos/logo-002.jpg" alt="Image 2">
            </div>
           <div  class="img2">
            <img width="150" height="40" src="https://scooter-promotos.tn/photos/LogoZitouna.png" alt="Image 1">
            </div>
           
          
            </div>
        <h1>Devis </h1>
        <div class="info">
            <strong>N°: </strong>  ${params.row.id}
        </div>
        <div class="info">
            <strong>Date:</strong>  ${params.row.createdAt}
        </div>
        <div class="info">
            <strong>Modéle</strong> ${params.row.Modele}
        </div>
        <div class="info">
            <strong>Quantité</strong> ${params.row.Qt}
        </div>
        <div class="info">
            <strong>Couleur:</strong>  ${params.row.Color}
        </div>
         <div  class="images" >
          <div class="info">
            <strong>Image</strong>
        </div>
            <img width="300" height="300" src= ${params.row.image}  alt="Image 3">
            </div>
       
        <div class="info">
            <strong>Prix Public Conseillé :</strong> ${params.row.PrixPublic.toFixed(3)} TND
        </div>
        <div class="info">
            <strong>Prix de Vente BZ :</strong> ${params.row.PrixTTCBZ.toFixed(3)} TND
        </div>
        <div class="info">
            <strong>Mensualité Fixe :</strong> ${params.row.MensualiteFix}
        </div>
        
        <div class="info">
            <strong>Nom & Prenom :</strong>  ${params.row.LibTiers}
        </div>
        <div class="info">
            <strong>Télephone </strong> 27453091
        </div>
         <div class="info">
            <strong>Adresse </strong> ${params.row.Adresse}
         </div>
         <div class="info">
            <strong>Durée de financement </strong>${params.row.DureeFinancement}         
         </div>
          <div class="info">
            <strong>MF </strong> ${params.row.MF}           
         </div>
          <div class="info">
            <strong>RIB </strong> ${params.row.Rib}          
         </div>
          <div class="info">
            <strong>IBAN </strong> TN59 ${params.row.Rib}           
         </div>
    </div>
</body>
</html>
`


          const printWindow = window.open('', '', 'height=400,width=600');
       
          printWindow.document.write(printContent);
        
          printWindow.document.close();
          // printWindow.print();
          printWindow.onload = () => {
            setTimeout(() => {
                printWindow.print();
            }, 500); // Ajout d'un délai pour charger les images
        };
        };
        const handlevalider = () => {
          const rowData = params.row;
          axios.put(`${serverip}/commandes/confirmer/${rowData?.id}`)
            .then((res) => {
              if (res.data.success === true) {
                toast.success(res.data.message);

                window.location.reload()
              } else {
                toast.error(res.data.message);
              }

            })
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        }
        const handleSupprimer = () => {
          const rowData = params.row;
          axios.put(`${serverip}/commandes/annuler/${rowData?.id}`)
            .then((res) => {
              if (res.data.success === true) {
                toast.success(res.data.message);

                window.location.reload()
              } else {
                toast.error(res.data.message);
              }

            })
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        }
        return (
          <div>
           
            <Button onClick={handlevalider}>
              <AiOutlineCheck size={20} />
            </Button>
          
            <Button onClick={handlePrint}>
              <FaPrint size={20} />
            </Button>
            
            <Button onClick={handleSupprimer}>
              <AiOutlineDelete size={20} />
            </Button>
          </div>
        );
      },
    },



  ];
  const uniqueAgences = [...new Set(adminOrders?.map(item => item.Agence))];
  const uniqueAgents = [...new Set(adminOrders?.map(item => item.agent))];
  const uniqueDR = [...new Set(adminOrders?.map(item => item.DR))];

  
  const rows = [];
  adminOrders &&
  adminOrders.forEach((item) => {
   
      rows.push({
        id: item.Nf,
        createdAt: item?.MDate.slice(0, 10),
        CodTiers: item?.CodTiers,
        LibTiers: item?.LibTiers,
        Modele : item?.Modele ,
        Color : item?.Color ,
        CodArt : item?.CodArt ,
        LibArt: item?.LibArt,
        image : item?.image,
        Qt : item?.Qt,
        PuHT : item?.PuHT,
        PuTTC :item?.PuTTC ,
        MntTVA : item?.MntTVA,
        nbr: item?.nbr,
        NumChass: item?.NumChass,
        Matricule: item?.Matricule,
        agent: item?.agent,
        CodeAgence: item?.CodeAgence,
        Agence: item?.Agence,
        DR: item?.DR,
        ResponsableDR: item?.ResponsableDR,
        CodStatut: item?.CodStatut,
        status: item?.LibStatut ,
        Rib : item?.Rib ,
        MF : item?.MF ,
        DureeFinancement : item?.DureeFinancement,
        MensualiteFix : item?.MensualiteFix,
        PrixPublic : item?.PrixPublic,
        PrixTTCBZ :item?.PrixTTCBZ
      });
    
      
    });
    const filteredRows = rows.filter(row => {
      const matchesAgency = agencyFilter.length ? agencyFilter.includes(row.Agence) : true;
      const matchesAgent = agentFilter.length ? agentFilter.includes(row.agent) : true;
      const matchesDR = drFilter.length ? drFilter.includes(row.DR) : true;
      const matchesStatut = statusFilter.length ? statusFilter.includes(row.CodStatut) : true;
     const rowDate = new Date(row.createdAt); // Assuming createdAt is in YYYY-MM-DD format
     const startDateValid = startDate ? rowDate >= new Date(startDate) : true;
     const endDateValid = endDate ? rowDate <= new Date(endDate) : true;
 
      return  matchesStatut &&  matchesAgency && matchesAgent && matchesDR && startDateValid && endDateValid;;
    });
    const countOrdersByStatus = () => {
      const counts = {
        "0": 0,
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
        "5": 0,
        // Add more statuses if needed
      };
      
      filteredRows.forEach(row => {
        counts[row.CodStatut] = (counts[row.CodStatut] || 0) + 1;
      });
  
      return counts;
    };
  
    const orderCounts = countOrdersByStatus();

    const  filter=()=>{
      if (searchTerm !== "") {
            const filtered = rows.filter((item) => {
          return Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )})
         setitem(filtered)
       }else{
         setitem(rows)
          }
      }
    
  
       useEffect(()=>{
  filter()
       } ,[searchTerm])
  
  return (
    <>
   <AdminHeader /> 
      <div className="w-full flex">
        <div className="flex items-start justify-between w-full">
          <div className="w-[80px] 800px:w-[330px]">
            <AdminSideBar active={2} />
          </div>
        <div className="w-full p-4">
      
      <div className="flex items-center justify-between pb-2">
      <h3 className="text-[22px] font-Poppins">Suivi des Commandes </h3>
      
      </div>
      <div className="flex gap-4 my-2">
      <BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
        <IoCart className="text-2xl text-white" /> 
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Commandes</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">{filteredRows.length}</strong>
					
					</div>
				</div>
			</BoxWrapper>
      <BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-red-600">
					 <IoCart className="text-2xl text-white" /> 
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Annulées</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">{orderCounts["0"]}</strong>
						
					</div>
				</div>
			</BoxWrapper>
			
			<BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-black">
        <IoCart className="text-2xl text-white" /> 
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">En attente</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">{orderCounts["1"]}</strong>
					
					</div>
				</div>
			</BoxWrapper>
      <BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-orange-400">
        <IoCart className="text-2xl text-white" /> 
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Devis prét</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">{orderCounts["2"]}</strong>
						
					</div>
				</div>
			</BoxWrapper>
			<BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-green-600">
        <IoCart className="text-2xl text-white" /> 
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">En Att. confirmation admin</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">{orderCounts["3"]}</strong>
						
					</div>
				</div>
			</BoxWrapper>
      <BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-400">
        <IoCart className="text-2xl text-white" />  
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Confirmées</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">{orderCounts["4"]}</strong>
						
					</div>
				</div>
			</BoxWrapper>
			
      <BoxWrapper>
				<div className="rounded-full h-12 w-12 flex items-center justify-center bg-violet-400">
        <IoCart className="text-2xl text-white" /> 
				</div>
				<div className="pl-4">
					<span className="text-sm text-gray-500 font-light">Livrées</span>
					<div className="flex items-center">
						<strong className="text-xl text-gray-700 font-semibold">{orderCounts["5"]}</strong>
						
					</div>
				</div>
			</BoxWrapper>
		</div>
      <div className="flex space-x-4 mb-4">
     
            <div >
           <label>
            Date Début  
           </label>
           <input
             type="date"
             name="price"
             id="start-date"
            
             value={startDate}
            className="mt-2 appearance-none block w-full px-3 h-[37px] border border-gray-300 rounded-[5px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setStartDate(e.target.value)}
            //  onChange={handleStartDateChange}
            // min={today}
             placeholder="Enter your event product stock..."
          />
          </div> 
                  <div>
          <label>
             Date Fin  
           </label>
           <input
             type="date"
             name="price"
             id="start-date"
             value={endDate}
             className="mt-2 appearance-none block w-full px-3 h-[37px] border border-gray-300 rounded-[5px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            //  onChange={handleEndDateChange}
            onChange={(e) => setEndDate(e.target.value)}
             placeholder="Enter your event product stock..."
           />
         </div>
         <div>
      <label className="pb-2">
     Statut
      </label>
      <Typeahead
          id="status-filter"
          onChange={(selected) => setStatusFilter(selected.map(item => item.id))}
          options={statusOptions}
          placeholder="Rechercher status..."
          selected={statusOptions.filter(option => statusFilter.includes(option.id))}
          multiple
          labelKey="label" // Display the label in the dropdown
        />
      
    </div>
         <div>
      <label className="pb-2">
      Toutes les DR
      </label>
      <Typeahead
        id="typeahead"
        onChange={setDrFilter}
        options={uniqueDR}
        placeholder="Rechercher DR..."
        selected={drFilter}
       
        multiple={false} // Set to true if you want to allow multiple selections
     
      />
      
    </div>
         <div>
      <label className="pb-2">
       Tous les Agence 
      </label>
      <Typeahead
        id="typeahead"
        onChange={setAgencyFilter}
        options={uniqueAgences}
        placeholder="Rechercher Agence..."
        selected={agencyFilter}
       
        multiple={false} // Set to true if you want to allow multiple selections
        
      />
      
    </div>
    <div>
      <label className="pb-2">
        Tous les Agent 
      </label>
      <Typeahead
        id="typeahead"
        onChange={setAgentFilter}
        options={uniqueAgents}
        placeholder="Rechercher Agent..."
        selected={agentFilter}
      
        multiple={false} // Set to true if you want to allow multiple selections
       
      />
     
    </div>
    <button 
        onClick={handleExportToExcel}
        className=" px-4 py-2 bg-blue-600 text-white rounded"
      >
        Export to Excel
      </button>
        </div>
     

<div className="w-full min-h-[45vh] bg-white rounded">
          <DataGrid
            rows={filteredRows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
       
      </div>
    </div>
      </div>
    
  </>
 
  );
};

export default AdminDashboardOrders;
