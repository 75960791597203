import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { AiOutlineArrowRight, AiOutlineMoneyCollect } from "react-icons/ai";
import { MdBorderClear } from "react-icons/md";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfAdmin } from "../../redux/actions/order";
import Loader from "../Layout/Loader";
import { getAllSellers } from "../../redux/actions/sellers";
import { serverip } from "../../server";
import axios from "axios";

const AdminDashboardMain = () => {
  const dispatch = useDispatch();

  const { adminOrders,adminOrderLoading } = useSelector((state) => state.order);
  const { sellers } = useSelector((state) => state.seller);
  const [ClassDR , setClassDR]=useState([])
  const [ClassAgent , setClassAgent]=useState([])
  const [ClassAgence , setClassAgence]=useState([])

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
    dispatch(getAllSellers());
  }, []);

useEffect(()=>{
  ClassementAgence()
  ClassementAgent()
  ClassementDR()
},[])
  const ClassementDR= async() => {
    const { data } = await axios.get(`${serverip}/Classement/DR`);
    setClassDR(data.orders)
  }
  const ClassementAgence= async() => {
    const { data } = await axios.get(`${serverip}/Classement/Agence`);
    setClassAgence(data.orders)
  }
  const ClassementAgent= async() => {
    const { data } = await axios.get(`${serverip}/Classement/Agent`);

    setClassAgent(data.orders)
  }

  const columns = [
    { field: "id", headerName: "ID",  flex: 0.4 },
    {
      field: "createdAt",
       headerName: "Date",
      flex: 0.4,
     },
     { field: "CodTiers", headerName: "Code", flex: 0.5 },
    { field: "LibTiers", headerName: "Raison Sociale", minWidth: 150, flex: 0.7 },
    {
      field: "Tel",
      headerName: "Tél",     
      flex: 0.4,hide : true
    },
    { field: "Matricule", headerName: "Matricule", flex: 0.5 ,hide :true},
    { field: "agent", headerName: "Agent", minWidth: 150, flex: 0.7 },
    { field: "CodeAgence", headerName: "Code", flex: 0.35 ,hide :true},
    { field: "Agence", headerName: "Agence", minWidth: 150, flex: 0.7 },
    { field: "DR", headerName: "DR", flex: 0.3 ,hide :true},
    { field: "ResponsableDR", headerName: "ResponsableDR", minWidth: 150, flex: 0.5 ,hide :true},
   
    
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      renderCell: (params) => {

        const { status } = params.row;

        let statusText;
        let statusClass;

        switch (status) {
          case "En attente":

            statusClass = 'bg-black text-white';
            break;
            case "Devis Prêt":

            statusClass = 'bg-orange-400 text-orange-100';
            break;
          case "En att. confirmation admin":

            statusClass = 'text-green-600 bg-green-100';
            break;
          case "Confirmée":

            statusClass = 'text-yellow-600 bg-yellow-100';
            break;
          case "Livrée":

            statusClass = 'text-teal-600 bg-teal-100';
            break;
          default:

            statusClass = 'text-red-600 bg-red-100';
        }

        return (
          <span className={`capitalize py-1 px-2 rounded-md text-xs ${statusClass}`}>
            {status.toLowerCase()}
          </span>
        );
      }
    },
    

    // {
    //   field: "total",
    //   headerName: "Total",
    //   type: "number",
    //   minWidth: 130,
    //   flex: 0.8,
    // },
   
  ];
  const columnsAgent = [
    { field: "id", headerName: "N°",  flex: 0.25 },
    {
      field: "Matricule",
       headerName: "Matricule",
      flex: 0.3,hide :true
     },
     {
      field: "Agent",
       headerName: "Agent",
      flex: 0.6,
     },
     {
      field: "nbr",
       headerName: "nbr",
      flex: 0.3,
     },

  ]
  const rowDR = []; 
  ClassDR && ClassDR.forEach((item) =>{
    rowDR.push({
    id :item?.Row,
    DR: item?.DR,
    nbr : item?.nbr
    })
  })
  const columnsDR = [
    { field: "id", headerName: "N°",  flex: 0.25 },
    
     {
      field: "DR",
       headerName: "DR",
      flex: 0.6,
     },
     {
      field: "nbr",
       headerName: "nbr",
      flex: 0.3,
     },

  ]
  const rowAgent = []; 
  ClassAgent && ClassAgent.forEach((item) =>{
    rowAgent.push({
    id :item?.Row,
    Matricule: item?.Matricule ,
    Agent : item?.Agent,
    nbr : item?.nbr
    })
  })
  const columnsAgence = [
    { field: "id", headerName: "N°",  flex: 0.25 },
    
     {
      field: "Agence",
       headerName: "Agence",
      flex: 0.6,
     },
     {
      field: "nbr",
       headerName: "nbr",
      flex: 0.3,
     },

  ]
  const rowAgence = []; 
  ClassAgence && ClassAgence.forEach((item) =>{
    rowAgence.push({
    id :item?.Row,
    Agence : item?.Agence ,
    nbr : item?.nbr
    })
  })
  const row = [];
  adminOrders &&
  adminOrders.forEach((item) => {
      row.push({
        id: item.Nf,
        createdAt: item?.MDate.slice(0,10),
        CodTiers: item?.CodTiers,
        LibTiers : item?.LibTiers ,
        Tel : item?.Remarq1 , 
        Matricule: item?.Matricule ,
        agent : item?.agent,
        CodeAgence :item?.CodeAgence,
        Agence: item?.Agence,
        DR :item?.DR,
        ResponsableDR : item?.ResponsableDR,
        CodStatut: item?.CodStatut,
        status: item?.LibStatut
      });
    });

  return (
   <>
    {
      adminOrderLoading ? (
        <Loader />
      ) : (
        <div className="w-full p-4">
        <h3 className="text-[22px] font-Poppins pb-2">Classement</h3>
        <div className="w-full block 800px:flex items-center justify-between">
          <div className="w-full mb-4 800px:w-[30%] min-h-[20vh] bg-white shadow rounded px-2 py-5">
            <div className="flex items-center">
            <DataGrid
            rows={rowAgent}
            columns={columnsAgent}
            pageSize={5}
            disableSelectionOnClick
            autoHeight
          />
             
            </div>
            
          </div>
  
          <div className="w-full mb-4 800px:w-[30%] min-h-[20vh] bg-white shadow rounded px-2 py-5">
            <div className="flex items-center">
            <DataGrid
            rows={rowAgence}
            columns={columnsAgence}
            pageSize={5}
            disableSelectionOnClick
            autoHeight
          />
             
            </div>
            
          </div>
  
          <div className="w-full mb-4 800px:w-[30%] min-h-[20vh] bg-white shadow rounded px-2 py-5">
            <div className="flex items-center">
            <DataGrid
            rows={rowDR}
            columns={columnsDR}
            pageSize={5}
            disableSelectionOnClick
            autoHeight
          />
              
            </div>
            
          </div>
        </div>
  
        <br />
        <h3 className="text-[22px] font-Poppins pb-2">Les dernières commandes</h3>
        <div className="w-full min-h-[45vh] bg-white rounded">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={5}
            disableSelectionOnClick
            autoHeight
          />
        </div>
      </div>
      )
    }
   </>
  );
};

export default AdminDashboardMain;
