import React, { useState } from "react";
import styles from "../../styles/styles";
import { Country, State } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';
import { getAllUsers } from "../../redux/actions/user";
import { useEffect } from "react";
import axios from "axios";
import { server ,serverip } from "../../server";
import { toast } from "react-toastify";

const Checkout = () => {
  const { user } = useSelector((state) => state.user);
  const { cart } = useSelector((state) => state.cart);
  const { success, error } = useSelector((state) => state.events);
  const [country, setCountry] = useState("TN");
  const [city, setCity] = useState("");
  const [Financement,setFinancement] = useState("");
  const [natureClient,setnatureClient] = useState("");
  const [Agence, setAgence] = useState([]);
  const [Agent, setAgent] = useState([]);
  const [address2, setAddress2] = useState("");
  const [zipCode, setZipCode] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeData, setCouponCodeData] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  

  const flattenedCart = cart.flatMap(item => Array(item.qty).fill({
    CodArt: item.UGS,
    qty: 1 ,
    DesSFam : item.DesSFam    
  }));
  

  const Valider = async () => {
    const order ={
      products : flattenedCart ,
      // user : user ,
      Agence : Agence.length > 0 ? Agence [0]['label']: user.Agence ,
      CodeAgence : Agence.length > 0 ? Agence [0]['code']: user.CodeAgence ,
      Matricule : Agent.length > 0 ? Agent [0]['Matricule']: user.Matricule ,
      Agent : Agent.length > 0 ? Agent [0]['label'] : user.Nom + ' ' + user.Prenom ,
      Financement : Financement ,
      natureClient : natureClient
    }

    await axios
    .post(`${serverip}/create-order`, order)
    .then((res) => {
     
      navigate("/profile");
      window.location.reload();
      toast.success("");
      localStorage.setItem("cartItems", JSON.stringify([]));
      localStorage.setItem("latestOrder", JSON.stringify([]));
    
    });   


  };

  const subTotalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );

  // this is shipping cost variable
  const shipping = subTotalPrice * 0.1;

  

  const discountPercentenge = couponCodeData ? discountPrice : "";

  const totalPrice = couponCodeData
    ? (subTotalPrice + shipping - discountPercentenge).toFixed(2)
    : (subTotalPrice + shipping).toFixed(2);



  return (
    <div className="w-full flex flex-col items-center py-8">
      <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
        <div className="w-full 800px:w-[65%]">
          <ShippingInfo
            user={user}
            country={country}
            setCountry={setCountry}
            city={city}
            setCity={setCity}
            Financement={Financement}
            setFinancement={setFinancement}
            natureClient={natureClient}
            setnatureClient={setnatureClient}
            Agence={Agence}
            setAgence={setAgence}
            Agent={Agent}
            setAgent={setAgent}
            address2={address2}
            setAddress2={setAddress2}
            zipCode={zipCode}
            setZipCode={setZipCode}
          />
        </div>
       
      </div>
      <div
        className={`${styles.button} w-[150px] 800px:w-[280px] mt-10`}
        onClick={Valider}
      >
        <h5 className="text-white">Demander Devis</h5>
      </div>
    </div>
  );
};

const ShippingInfo = ({
  user,
  country,
  setCountry,
  city,
  setCity,
  Agence,
  setAgence,
  Agent,
  setAgent,
  Financement,
  setFinancement,
  natureClient,
  setnatureClient,
  address2,
  setAddress2,
  zipCode,
  setZipCode,
}) => {
  const { sellers } = useSelector((state) => state.seller);
  const { users  } = useSelector((state) => state.user);
  const [AllAgent, setAllAgent] = useState([]);
  
  

  const listeAgences = (item) => {
    if (item.length > 0) {
      const agence2 = item[0]['label']
    
      const data = users.filter(
         row => (row.Agence === agence2))
       setAllAgent(data)
      
      
     } else {
      setAllAgent(users)
     }
  }
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  return (
    <div className="w-full 800px:w-[95%] bg-white rounded-md p-5 pb-8">
      <h5 className="text-[18px] font-[500]">Information Client :</h5>
      <br />
      <form >
        <div className="w-full flex pb-3">
        <div className="w-full flex pb-3">
          <div className="w-[50%]">
            <label className="block pb-2"> Nature Client</label>
            <select
              className="w-[95%] border h-[40px] rounded-[5px]"
              value={natureClient}
               onChange={(e) => setnatureClient(e.target.value)}
            >


              <option className="block pb-2" value=""> Choisissez ... </option>
              <option key='1' value='Particulier'>Particulier </option>
              <option key='2' value='Professionnel'>Professionnel </option>
              <option key='3' value='Entreprise'>Entreprise </option>
            </select>
          </div>
          <div className="w-[50%]">
            <label className="block pb-2">Durée de financement</label>
            <select
              className="w-[95%] border h-[40px] rounded-[5px]"
              value={Financement}
              onChange={(e) => setFinancement(e.target.value)}
            >
              <option className="block pb-2" value="">
                Choisissez ...
              </option>
              <option key='1' value='24'>24</option>
              <option key='2' value='36'>36 </option>
            </select>
          </div>
        </div>
          
          
        </div>

        

        <div className="w-full flex pb-3">
          <div className="w-[50%]">
            <label className="block pb-2">Pays</label>
            <select
              className="w-[95%] border h-[40px] rounded-[5px]"
              value={country}
              // onChange={(e) => setCountry(e.target.value)}
            >
              <option className="block pb-2" value="">
              Choisissez votre Pays
              </option>
              {Country &&
                Country.getAllCountries().map((item) => (
                  <option key={item.isoCode} value={item.isoCode}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="w-[50%]">
            <label className="block pb-2">Ville</label>
            <select
              className="w-[95%] border h-[40px] rounded-[5px]"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            >
              <option className="block pb-2" value="">
                Choisissez votre ville
              </option>
              {State &&
                State.getStatesOfCountry(country).map((item) => (
                  <option key={item.isoCode} value={item.isoCode}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      {  user?.role==='admin' &&
        <div className="w-full flex pb-3">
          <div className="w-[50%]">
            <label className="block pb-2"
                
              >
                Agence<span className="text-danger fw-bold"> *</span>
              </label>
              <Typeahead
                className="w-[95%]"
                id="status-filter"
                onChange={(selected) => {setAgence(selected);listeAgences(selected)}}
                options={sellers}
                placeholder=""
                selected={Agence}
                defaultValue={Agence}
                required
                clearButton
                labelKey="label" // Display the label in the dropdown
              />
          </div>
          <div className="w-[50%]">
       
                 <label className="block pb-2">
                Agent<span className="text-danger fw-bold"> *</span>
              </label>
              <Typeahead
                 className="w-[99%]"
                id="status-filter"
                onChange={(selected) => setAgent(selected)}
                options={AllAgent}
                placeholder=""
                selected={Agent}
                defaultValue={Agent}
                required
                clearButton
                labelKey="label" // Display the label in the dropdown
              />
          </div>
        </div>
}
        <div></div>
      </form>
  
    </div>
  );
};

const CartData = ({
  handleSubmit,
  totalPrice,
  shipping,
  subTotalPrice,
  couponCode,
  setCouponCode,
  discountPercentenge,
}) => {
  return (
    <div className="w-full bg-[#fff] rounded-md p-5 pb-8">
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">subtotal:</h3>
        <h5 className="text-[18px] font-[600]">${subTotalPrice}</h5>
      </div>
      <br />
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">shipping:</h3>
        <h5 className="text-[18px] font-[600]">${shipping.toFixed(2)}</h5>
      </div>
      <br />
      <div className="flex justify-between border-b pb-3">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">Discount:</h3>
        <h5 className="text-[18px] font-[600]">
          - {discountPercentenge ? "$" + discountPercentenge.toString() : null}
        </h5>
      </div>
      <h5 className="text-[18px] font-[600] text-end pt-3">${totalPrice}</h5>
     
    </div>
  );
};

export default Checkout;
